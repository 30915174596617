import React, { useEffect, useMemo, useState } from "react";
import { FaLock, FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../../funcs/encryption";

const shuffleStyle = `
  @keyframes shuffleWiggle {
    0% { transform: translate(0, 0) rotate(0deg); }
    25% { transform: translate(-5px, 5px) rotate(-3deg); }
    50% { transform: translate(5px, -5px) rotate(3deg); }
    75% { transform: translate(-3px, 3px) rotate(-2deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
  }

  .shuffle-animation {
    animation: shuffleWiggle 1s ease-in-out;
  }

  .fade-points {
    transition: opacity 1s ease-out;
    opacity: 0;
  }
`;

/** Shuffle in-place */
const shuffleArray = (array) => {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

/** Sort descending by points (highest→lowest) */
const sortByPoints = (array) => {
  return [...array].sort((a, b) => {
    const aPoints = a.question?.points || a.customQuestion?.points || 0;
    const bPoints = b.question?.points || b.customQuestion?.points || 0;
    // b - a => descending
    return bPoints - aPoints;
  });
};

const Tarbaa = ({ game, setGame }) => {
  const navigate = useNavigate();

  const [showPoints, setShowPoints] = useState(true);
  const [isShuffling, setIsShuffling] = useState(false);

  // Identify the game code
  const gameCode = game?.codeInfo?.code || "";
  const shuffledKey = `shuffled_${gameCode}`;
  const fadedKey = `faded_${gameCode}`;

  // Check localStorage flags
  const hasShuffled = !!localStorage.getItem(shuffledKey);
  const hasFaded = !!localStorage.getItem(fadedKey);

  /**
   * Main effect controlling shuffle/fade once per game code.
   */
  useEffect(() => {
    if (!game || !gameCode) return;

    // If not fading questions => show points, no shuffle
    if (!game.fadedQuestions) {
      setShowPoints(true);
      setIsShuffling(false);
      return;
    }

    // If we've already done the fade => keep points hidden
    if (hasFaded) {
      setShowPoints(false);
      setIsShuffling(false);
      return;
    }

    // Otherwise, shuffle once if not done
    if (!hasShuffled) {
      const updatedGame = { ...game };
      updatedGame.modes = updatedGame.modes.map((mode) => {
        if (!mode?.questions) return mode;
        return { ...mode, questions: shuffleArray(mode.questions) };
      });

      // Store new game so parent sees it
      const enc = encryptData(updatedGame);
      localStorage.setItem("game", enc);
      setGame(updatedGame);

      localStorage.setItem(shuffledKey, "true");
    }

    // Now do wiggle => fade
    setShowPoints(true);
    setIsShuffling(true);

    const wiggleTimer = setTimeout(() => {
      setIsShuffling(false);
      const fadeTimer = setTimeout(() => {
        setShowPoints(false);
        localStorage.setItem(fadedKey, "true");
      }, 100);
      return () => clearTimeout(fadeTimer);
    }, 1000);

    return () => clearTimeout(wiggleTimer);
  }, [game, gameCode, setGame, hasShuffled, hasFaded, shuffledKey, fadedKey]);

  /**
   * finalModes:
   *   - If fadedQuestions = false => sort descending
   *   - If we have fully faded => keep as-is
   *   - Otherwise => the effect above sets the updatedGame
   */
  const finalModes = useMemo(() => {
    if (!game?.modes) return [];

    if (!game.fadedQuestions) {
      // Sort descending if not fading
      return game.modes.map((mode) => ({
        ...mode,
        questions: sortByPoints(mode.questions || []),
      }));
    }

    if (hasFaded) {
      // Already faded => keep as is
      return game.modes;
    }

    // Not yet faded => rely on updated shuffled game
    return game.modes;
  }, [game, hasFaded]);

  return (
    <div className="w-full h-[89vh]">
      <style>{shuffleStyle}</style>

      <div className="w-full h-[69vh] xl:h-[76vh] flex flex-row flex-wrap p-1 justify-center">
        {finalModes.map((mode, modeIndex) => (
          <div
            key={modeIndex}
            className="w-1/3 h-1/2 sm:w-1/6 sm:h-full md:text-xl xl:text-2xl font-bold"
          >
            <div
              className={`relative w-full h-full p-1 ${
                modeIndex !== 0 ? "border-l-[1px]" : ""
              }`}
            >
              {/* MODE HEADER */}
              <div
                className={`relative h-1/6 flex justify-center items-end rounded-xl text-center ${
                  mode?.custom && "border-4 border-mainText"
                }`}
              >
                <div className="z-20 h-full lg:h-2/4 flex justify-center items-center w-full bg-[#00000090] rounded-lg">
                  <h1 className="text-white text-center md:text-lg xl:text-xl">
                    {mode?.name}
                  </h1>
                </div>
                <div className="absolute w-full h-full z-0 overflow-hidden rounded-lg">
                  <img
                    src={mode?.image}
                    alt={mode?.name}
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>

              {/* QUESTIONS */}
              <div className="h-5/6">
                {mode?.questions?.map((q, qIndex, arr) => {
                  const isAnswered =
                    q?.question?.answered || q?.customQuestion?.answered;

                  let shouldDisable = isAnswered;
                  if (game?.lowToHighPoints && !isAnswered) {
                    // Instead of "firstUnansweredIndex", we find the "last" unanswered
                    // which is effectively the lowest points after descending sort
                    const lastUnansweredReversedIndex = [...arr]
                      .reverse()
                      .findIndex(
                        (x) =>
                          !(
                            x?.question?.answered || x?.customQuestion?.answered
                          )
                      );
                    // convert reversed index to normal index
                    const lastUnansweredIndex =
                      lastUnansweredReversedIndex === -1
                        ? -1
                        : arr.length - 1 - lastUnansweredReversedIndex;

                    // disable if not the last unanswered
                    shouldDisable = qIndex !== lastUnansweredIndex;
                  }

                  return (
                    <div
                      key={q._id}
                      className="h-1/6 pt-1 xl:pt-3 px-1 flex justify-center items-center relative"
                    >
                      <button
                        onClick={() => navigate(`/question/${q?._id}`)}
                        disabled={shouldDisable}
                        className={`
                          h-full w-full bg-[#bcc2d3] text-white rounded-xl text-center portrait:text-xl disabled:opacity-50
                          ${
                            game?.lowToHighPoints &&
                            !isAnswered &&
                            shouldDisable
                              ? "disabled:bg-[#474950]"
                              : "disabled:bg-[#d6d9e1]"
                          }
                          ${isShuffling ? "shuffle-animation" : ""}
                        `}
                      >
                        <h1 className={`${!showPoints ? "fade-points" : ""}`}>
                          {q?.question?.points || q?.customQuestion?.points}
                        </h1>
                      </button>

                      {/* Lock icon if disabled but unanswered (lowToHighPoints) */}
                      {game?.lowToHighPoints &&
                        !isAnswered &&
                        shouldDisable && (
                          <div className="absolute w-8 h-8 flex justify-center items-center opacity-50">
                            <FaLock className="text-dark size-6 xl:size-10" />
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* TEAMS / FOOTER */}
      <div className="w-full h-[21vh] xl:h-[14vh] flex justify-center items-center overflow-hidden bg-brown border-t-[1vh] border-mainButtonShadow">
        <div className="h-full w-full flex flex-row 2xl:p-2 justify-center">
          {/* Example Team Code Here */}
          {game?.teams?.map((team, index) => (
            <div className="w-1/4 h-full px-[1px] lg:p-2" key={index}>
              <div
                className={`w-full h-full flex flex-col sm:flex-row justify-center items-center bg-[#dd9c6e] rounded-lg ${
                  team.currentTurn === true && "border-4"
                }`}
              >
                <div className="w-full h-1/2 sm:w-1/3 sm:h-full flex justify-center items-center p-1 sm:p-2">
                  <div className="h-full aspect-square rounded-full overflow-hidden flex justify-center items-center">
                    <div className="w-full aspect-square rounded-full overflow-hidden">
                      <img
                        src={team?.image?.image}
                        alt={team?.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-xl lg:text-2xl font-bold portrait:text-xl">
                  <div className="h-1/2 flex items-center justify-center overflow-hidden">
                    <h1 className="text-white text-center">{team?.name}</h1>
                  </div>
                  <div className="w-full h-1/2 flex justify-between items-center p-2">
                    <button
                      onClick={() => {
                        const updatedGame = { ...game };
                        updatedGame.teams[index].points -= 50;

                        // 1) Update parent state so the UI re-renders immediately
                        setGame(updatedGame);

                        // 2) Optionally encrypt and store in localStorage for persistence
                        const encrypted = encryptData(updatedGame);
                        localStorage.setItem("game", encrypted);
                      }}
                      className="bg-mainButtonShadow hover:bg-mainButton rounded-lg p-2"
                    >
                      <FaMinus className="text-[#dd9c6e] size-3 xl:size-5" />
                    </button>
                    <h1 className="text-white font-bold">{team?.points}</h1>
                    <button
                      onClick={() => {
                        const updatedGame = { ...game };
                        updatedGame.teams[index].points += 50;

                        setGame(updatedGame);

                        const encrypted = encryptData(updatedGame);
                        localStorage.setItem("game", encrypted);
                      }}
                      className="bg-mainButtonShadow hover:bg-mainButton rounded-lg p-2"
                    >
                      <FaPlus className="text-[#dd9c6e] size-3 xl:size-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Teams */}
        </div>
      </div>
    </div>
  );
};

export default Tarbaa;
