// src/components/tarbaa/Question.jsx
import React, { useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import {
  FaClockRotateLeft,
  FaMoneyBillTransfer,
  FaXmark,
} from "react-icons/fa6";
import { HiPhone } from "react-icons/hi2";
import { TbSquareHalf } from "react-icons/tb";
import ReactPlayer from "react-player";
import { useStopwatch, useTimer } from "react-timer-hook";
import textSizeBasedOnLength from "../../funcs/textResizer";
import { encryptData } from "../../funcs/encryption";

const Question = ({ game, ads, question, setShowAnswer }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [selectedPowerUp, setSelectedPowerUp] = useState({ name: null });
  const [showFirstLetter, setShowFirstLetter] = useState(false);
  const { seconds, minutes, isRunning, start, pause, reset } = useStopwatch({
    autoStart: true,
  });
  const callTimer = useTimer({
    expiryTimestamp: Date.now() + 30000,
    autoStart: false,
  });

  const modeName = game?.modes?.find((mode) =>
    mode.questions.find(
      (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
    )
  )?.name;

  const handleFinishPowerUp = () => {
    const team = game.teams.find((t) => t._id === selectedPowerUp.team._id);
    const powerUp = team.powerUps.find((p) => p.name === selectedPowerUp.name);
    if (powerUp) {
      powerUp.used = true;
      // Encrypt and save updated game data
      localStorage.setItem("game", encryptData(game));
      setSelectedPowerUp({ name: null });
    }
  };

  return (
    <>
      <div className="w-full h-[90vh] flex flex-col portrait:flex-col sm:flex-row portrait:py-10 pl-10 pr-2 py-10 xl:py-20">
        <div
          className={`portrait:w-full portrait:h-3/4 relative h-3/4 w-full sm:w-5/6 sm:h-full ${textSizeBasedOnLength(
            question?.question
          )} font-bold text-dark border-8 border-behindButton rounded-[50px] pt-4 sm:pt-6 md:pt-8`}
        >
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 sm:w-full flex flex-row justify-between items-center bg-transparent">
            {/* Left Section */}
            <div className="w-1/3 flex justify-center items-center">
              <div
                className={`hidden sm:block border-[#343434] border-4 rounded-2xl ${
                  question?.spare ? "bg-behindButton" : ""
                } ${question?.custom ? "bg-mainText" : ""}`}
              >
                {question?.custom && (
                  <div className="text-center px-2 text-dark rounded-lg text-sm lg:text-xl">
                    فئة خاصة
                  </div>
                )}
                {question?.spare && (
                  <div className="text-center px-2 text-white rounded-lg text-sm lg:text-xl">
                    وسيلة مساعدة
                  </div>
                )}
                <div className="text-center p-1 px-4 lg:p-2 bg-[#343434] text-white rounded-lg text-sm lg:text-xl ">
                  {question?.spare
                    ? game?.teams?.find((team) => team.currentTurn).name
                    : modeName}
                </div>
              </div>
            </div>

            {/* Center Section (Timer Controls) */}
            <div className="w-1/3 flex justify-center items-center">
              <div className="p-1 px-4 md:p-4 bg-[#343434] text-white rounded-full flex gap-10 text-lg lg:text-3xl">
                <button onClick={() => (isRunning ? pause() : start())}>
                  {isRunning ? <FaPause /> : <FaPlay />}
                </button>
                <h1 className="text-center">
                  {minutes < 10 && "0"}
                  {minutes}:{seconds < 10 && "0"}
                  {seconds}
                </h1>
                <button onClick={() => reset()}>
                  <FaClockRotateLeft />
                </button>
              </div>
            </div>

            {/* Right Section */}
            <div className="w-1/3 flex justify-center items-center">
              <div className="hidden sm:block border-[#343434] border-4 rounded-2xl">
                <div className="text-right p-1 px-4 lg:p-2 bg-[#343434] text-white rounded-lg text-sm lg:text-xl flex gap-1">
                  <div>نقطة</div>
                  <div>{question?.points}</div>
                </div>
              </div>
            </div>
          </div>
          {/* Question Content */}
          <div className="h-2/6 xl:h-1/6 w-full flex justify-center items-top p-2  px-4 lg:px-10 overflow-scroll">
            <h1 style={{ direction: "rtl", textAlign: "center" }}>
              {question?.question}
            </h1>
          </div>
          <div className="h-4/6 xl:h-5/6 w-full flex justify-center items-start px-4 pb-4 xl:p-10">
            {(question?.questionVideo && question?.questionVideo.length > 10) ||
            (question?.questionAudio && question?.questionAudio.length > 10) ? (
              <ReactPlayer
                url={question?.questionVideo || question?.questionAudio}
                controls
                width="100%"
                height="100%"
              />
            ) : (
              <button
                onClick={() => {
                  setFullScreen(true);
                }}
                className="h-full"
              >
                <img
                  className="h-full object-contain"
                  src={question?.questionImage}
                  alt={question?.question}
                />
              </button>
            )}
          </div>

          {showFirstLetter && (
            <div
              style={{ direction: "rtl", textAlign: "center" }}
              className="absolute  bottom-0   transform -translate-x-1/2 translate-y-1/2 right-0 p-1 px-4 md:p-2 bg-[#343434] text-white rounded-lg  text-sm md:text-xl text-right"
            >
              {"أول حرف او رقم: " + question?.answer?.trim()[0]}
            </div>
          )}
          <div className="absolute bottom-0   transform translate-x-1/2 translate-y-1/2 ">
            <button
              className="bg-mainButton hover:bg-mainButtonShadow text-white font-bold py-3 px-4 md:px-10 border-b-8 border-mainButtonShadow hover:border-mainButtonShadow rounded-md text-sm md:text-xl"
              onClick={() => {
                setShowAnswer(true);
              }}
            >
              الإجابة
            </button>
          </div>
        </div>
        <div className=" portrait:h-1/4 portrait:w-full  h-1/4 w-full  sm:w-1/4 sm:h-full flex-row sm:flex-col portrait:flex-row flex justify-center items-center text-white text-4xl sm:pl-4 sm:gap-2 portrait:pt-6 pt-6 sm:pt-0">
          {game?.teams?.map((team, index) => (
            <div
              className="portrait:w-1/4 portrait:h-full w-full h-1/4 px-[1px] xl:p-2"
              key={index}
            >
              <div
                className={`portrait:flex-col  w-full h-full flex flex-col sm:flex-row justify-center items-center  bg-[#dd9c6e] rounded-lg        ${
                  team.currentTurn === true && "border-4 border-dark"
                }`}
              >
                <div className="portrait:w-full portrait:h-1/2 w-full h-1/2 sm:w-1/3  sm:h-full flex  justify-center items-center p-1 sm:p-2">
                  <div className=" h-full  aspect-square rounded-full overflow-hidden flex justify-center items-center">
                    <div className=" w-full aspect-square rounded-full overflow-hidden ">
                      <img
                        src={team?.image?.image}
                        alt={team?.name}
                        className="w-full h-full object-cover "
                      />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-xl xl:text-2xl font-bold ">
                  <div className="h-1/2 flex items-center justify-center overflow-hidden">
                    <h1 className="text-white text-center">{team?.name}</h1>
                  </div>
                  <div className="portrait:w-full portrait:h-1/2 w-full h-1/2 flex justify-center items-center p-2 gap-1 md:gap-4">
                    {team?.powerUps.map((powerUp, index) => (
                      <button
                        disabled={
                          powerUp.used ||
                          (!team.currentTurn && powerUp?.name === "one")
                        }
                        onClick={() => {
                          setSelectedPowerUp({ name: powerUp?.name, team });
                        }}
                        className="bg-mainButtonShadow hover:bg-mainButton rounded-md md:rounded-lg p-1 md:p-2 text-sm  md:text-lg xl:text-2xl disabled:bg-[#94674690] disabled:text-[#ffffff90]"
                        key={index}
                      >
                        {powerUp?.name === "one" ? (
                          <FaMoneyBillTransfer />
                        ) : powerUp?.name === "two" ? (
                          <HiPhone />
                        ) : (
                          <TbSquareHalf />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {fullScreen && (
        <button
          onClick={() => {
            setFullScreen(false);
          }}
          className="absolute h-[100vh] w-[100vw] bg-[#00000090]"
        >
          <img
            src={question?.questionImage}
            alt={question?.question}
            className="h-full w-full object-contain"
          />
        </button>
      )}
      {selectedPowerUp.name && (
        <>
          <div className="z-[9999999] justify-center items-center flex fixed inset-0 outline-none focus:outline-none text-right">
            <div className="relative w-auto my-6 mx-auto max-w-lg md:max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className=" flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t gap-6">
                  <div>
                    <button
                      className=" p-1 ml-auto bg-transparent border-0 text-black opacity-15 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setSelectedPowerUp({ name: null })}
                    >
                      <FaXmark />
                    </button>
                  </div>
                  <div className="flex flex-row justify-end items-center gap-2">
                    <h1>{selectedPowerUp.team.name}</h1>
                    <img
                      className="h-10 aspect-square rounded-full"
                      src={selectedPowerUp?.team?.image?.image}
                      alt={selectedPowerUp?.team?.name}
                    />
                  </div>
                  <div className=" flex flex-col gap-2">
                    <h3 className="text-3xl font-semibold text-right">
                      {selectedPowerUp.name === "one"
                        ? "ما عجبني بغيّره"
                        : selectedPowerUp.name === "two"
                        ? "اتصال بصديق"
                        : "أول حرف"}
                    </h3>
                  </div>
                </div>
                {/*body*/}
                <div className="relative p-6 flex flex-col justify-center items-center gap-5">
                  <h1 className="text-center font-bold">
                    {selectedPowerUp.name === "one"
                      ? "تقدر تغيّر السؤال حق سؤال عشوائي ثاني بس ها! اذا جاوبته صح بتاخذ نقاط السؤال اليديد"
                      : selectedPowerUp.name === "two"
                      ? "أول ما يرد الي داقين عليه تبلشون التايمر"
                      : "لما تستعملها بيطلع اول حرف او رقم من الجواب"}
                  </h1>
                  {selectedPowerUp.name === "two" && (
                    <>
                      <button
                        className="border-4 h-36 aspect-square rounded-full overflow-hidden  font-semibold text-2xl"
                        onClick={() => callTimer.resume()}
                      >
                        <h1
                          className={`${
                            callTimer.totalSeconds > 15 &&
                            callTimer.totalSeconds <= 30 &&
                            callTimer.isRunning
                              ? "text-green-400"
                              : callTimer.totalSeconds > 0 &&
                                callTimer.totalSeconds <= 15
                              ? "text-yellow-400"
                              : callTimer.totalSeconds === 0
                              ? "text-red-400"
                              : "text-dark"
                          }`}
                        >
                          {callTimer.totalSeconds > 0 &&
                          callTimer.totalSeconds <= 30 &&
                          callTimer.isRunning ? (
                            (callTimer < 10 && "0") + callTimer.totalSeconds
                          ) : callTimer.totalSeconds === 0 ? (
                            "انتهى الوقت"
                          ) : (
                            <div className=" flex justify-center items-center flex-col">
                              <h1>بلش التايمر</h1>
                              <HiPhone />
                            </div>
                          )}
                        </h1>
                      </button>
                    </>
                  )}
                  <button
                    onClick={
                      selectedPowerUp.name === "one"
                        ? () => {
                            const randomQuestion =
                              game.spareQuestions[
                                Math.floor(
                                  Math.random() * game.spareQuestions.length
                                )
                              ];
                            game.spareQuestions = game.spareQuestions.filter(
                              (q) => q._id !== randomQuestion._id
                            );
                            // Replace the current question in the mode with the new question
                            const mode = game.modes.find((mode) =>
                              mode.questions.find(
                                (q) =>
                                  (q?.question?._id ||
                                    q?.customQuestion?._id) === question?._id
                              )
                            );
                            const qIndex = mode.questions.findIndex(
                              (q) =>
                                (q?.question?._id || q?.customQuestion?._id) ===
                                question?._id
                            );
                            if (qIndex !== -1) {
                              mode.questions[qIndex].question = {
                                ...randomQuestion,
                                spare: true,
                              };
                            }
                            // Encrypt and save
                            localStorage.setItem("game", encryptData(game));
                            setSelectedPowerUp({ name: null });
                            handleFinishPowerUp();
                            window.location.reload();
                          }
                        : selectedPowerUp.name === "two"
                        ? () => {
                            callTimer.restart(new Date(Date.now() + 30000));
                            callTimer.pause();
                            handleFinishPowerUp();
                          }
                        : () => {
                            setShowFirstLetter(true);
                            handleFinishPowerUp();
                          }
                    }
                    className="bg-secondaryButton text-white p-4 rounded-xl"
                  >
                    {selectedPowerUp.name === "one"
                      ? "غيّر السؤال"
                      : selectedPowerUp.name === "two"
                      ? "خلصت المكالمة"
                      : "إستعمال"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default Question;
