// src/components/Game.jsx
import { useQuery } from "@tanstack/react-query";
import { treadmill } from "ldrs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getGameByCode } from "../api/game";
import SJHeader from "../components/header/SJHeader";
import MKH from "../components/themes/MKH";
import SJ from "../components/themes/SJ";
import Tarbaa from "../components/themes/Tarbaa";
import MKHHeader from "../components/header/MKHHeader";
import TarbaaHeader from "../components/header/TarbaaHeader";

// Import encryption utilities
import { encryptData, decryptData } from "../funcs/encryption";

const Game = () => {
  const { code } = useParams();
  const encryptedLocalGame = localStorage.getItem("game");
  const [game, setGame] = useState(
    encryptedLocalGame ? decryptData(encryptedLocalGame) : null
  );
  const [theme, setTheme] = useState(null);
  // const [ads, setAds] = useState(null);
  const navigate = useNavigate();

  treadmill.register();

  const { data, isError, isLoading, error } = useQuery({
    queryKey: ["game", code],
    queryFn: () => getGameByCode(code),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // const { data: activeAds, isLoading: adsLoading } = useQuery({
  //   queryKey: ["ads"],
  //   queryFn: () => getAds(),
  //   retry: false,
  //   refetchOnMount: false,
  //   refetchOnWindowFocus: false,
  // });

  // useEffect(() => {
  //   if (!adsLoading) {
  //     if (activeAds) {
  //       // Encrypt ads before storing
  //       localStorage.setItem("ads", activeAds);
  //       setAds(activeAds);
  //     } else {
  //       const ads = localStorage.getItem("ads");
  //       setAds(ads);
  //     }
  //   }
  // }, [activeAds, adsLoading]);

  useEffect(() => {
    const encryptedGame = localStorage.getItem("game");
    setGame(encryptedGame ? decryptData(encryptedGame) : null);

    if (
      code === "old" &&
      encryptedLocalGame !== null &&
      encryptedLocalGame !== undefined
    ) {
      return;
    } else {
      if (!isLoading) {
        if (data) {
          if (game && game?.codeInfo?.code === code) {
            return;
          }
          // Clone the data to avoid mutating original
          const clonedData = { ...data };

          // Set currentTurn to true for a random team
          const randomIndex = Math.floor(
            Math.random() * clonedData.teams.length
          );
          clonedData.teams = clonedData.teams.map((team, index) => ({
            ...team,
            currentTurn: index === randomIndex,
          }));

          // Encrypt and save to local storage
          const encryptedData = encryptData(clonedData);
          localStorage.setItem("game", encryptedData);

          //remove anything in the localstorage that starts with faded or shuffled
          Object.keys(localStorage).forEach((key) => {
            if (key.startsWith("faded") || key.startsWith("shuffled")) {
              localStorage.removeItem(key);
            }
          });

          // Optionally update state without reloading
          setGame(clonedData);
          window.location.reload();
        } else {
          if (error?.response?.data?.message === "invalid code") {
            toast.error("الكود غير صحيح او تم استعماله من قبل");
            localStorage.removeItem("game");
            navigate("/");
          }

          if (!game || game === null) {
            toast.error("الكود غير صحيح او تم استعماله من قبل");
            localStorage.removeItem("game");
            //remove anything in the localstorage that starts with faded or shuffled
            Object.keys(localStorage).forEach((key) => {
              if (key.startsWith("faded") || key.startsWith("shuffled")) {
                localStorage.removeItem(key);
              }
            });
            navigate("/");
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [data, isError]);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      setTheme(theme);
    }
  }, []);

  useEffect(() => {
    // Prevent right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  if (isLoading && !game) {
    return (
      <div className="h-[100vh] w-full flex justify-center items-center flex-col gap-5">
        <h1 className="text-3xl text-primaryButton">...جاري التحميل</h1>
        <l-treadmill size="120" speed="1.1" color="#516797" />
      </div>
    );
  }

  return (
    <div className="h-full w-full flex flex-col font-sans select-none ">
      {theme === "MKH" ? (
        <>
          <MKHHeader theme={theme} game={game} setGame={setGame} />
          <MKH theme={theme} game={game} setGame={setGame} />
        </>
      ) : theme === "SJ" ? (
        <>
          <SJHeader theme={theme} game={game} setGame={setGame} />
          <SJ theme={theme} game={game} setGame={setGame} />
        </>
      ) : (
        <>
          <TarbaaHeader theme={theme} game={game} setGame={setGame} />
          <Tarbaa theme={theme} game={game} setGame={setGame} />
        </>
      )}
    </div>
  );
};

export default Game;
